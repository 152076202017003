const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  addMode: false,
  editMode: false,
  viewMode: false,
  deleteMode: false,
  advertiseMode: false,
  customSlideMode: false,
  notifyMode: false,
  pinVoucher: false,
  removePinVoucher: false,
  addProviderDepartment: false,
  addSection:false,
  itemToEdit: null,
  itemToView: null,
  itemToDelete: null,
  itemToNotify: null,
  itemToCustomSlide: null,
  itemToPin: null,
  itemToRemovePin: null,
  itemToAdd: null,
  itemToAddSection: null,
  itemToProviderDepartment: null,
  itemToSection:null
};

const VoucherSlice = createSlice({
  name: "voucher",
  initialState,
  reducers: {
    setAddMode: (state, action) => {
      state.addMode = action.payload;
    },
    setEditMode: (state, action) => {
      state.editMode = action.payload;
    },
    setViewMode: (state, action) => {
      state.viewMode = action.payload;
    },
    setDeleteMode: (state, action) => {
      state.deleteMode = action.payload;
    },
    setAdvertiseMode: (state, action) => {
      state.advertiseMode = action.payload;
    },
    setCustomSlideMode: (state, action) => {
      state.customSlideMode = action.payload;
    },
    setNotifyMode: (state, action) => {
      state.notifyMode = action.payload;
    },
    setPinVoucher: (state, action) => {
      state.pinVoucher = action.payload;
    },
    setRemovePinVoucher: (state, action) => {
        state.removePinVoucher = action.payload;
    },
    setItemToEdit: (state, action) => {
      state.itemToEdit = action.payload;
    },
    setItemToView: (state, action) => {
      state.itemToView = action.payload;
    },
    setItemToDelete: (state, action) => {
      state.itemToDelete = action.payload;
    },
    setItemToAdvertise: (state, action) => {
      state.itemToAdvertise = action.payload;
    },
    setItemToNotify: (state, action) => {
      state.itemToNotify = action.payload;
    },
    setItemToCustomSlide: (state, action) => {
      state.itemToCustomSlide = action.payload;
    },
    setItemToPin: (state, action) => {
      state.itemToPin = action.payload;
    },
    setItemToRemovePin: (state, action) => {
      state.itemToRemovePin = action.payload;
    },
    setAddProviderDepartment: (state, action) => {
      state.addProviderDepartment = action.payload;
    },
    setItemToAdd: (state, action) => {
      state.itemToAdd = action.payload
    },
    setItemToProviderDepartment: (state, action) => {
      state.itemToProviderDepartment = action.payload
    },
    setAddSection: (state, action) => {
      state.addSection = action.payload;
    },
    setItemToAddSection: (state, action) => {
      state.itemToAddSection = action.payload
    },
    setItemToSection: (state, action) => {
      state.itemToSection = action.payload
    },
  },
});

export const {
  setAddMode,
  setEditMode,
  setViewMode,
  setDeleteMode,
  setAdvertiseMode,
  setCustomSlideMode,
  setNotifyMode,
  setPinVoucher,
  setRemovePinVoucher,
  setItemToEdit,
  setItemToView,
  setItemToDelete,
  setItemToAdvertise,
  setItemToNotify,
  setItemToCustomSlide,
  setItemToPin,
  setItemToRemovePin,
  setAddProviderDepartment,
  setItemToAdd,
  setItemToProviderDepartment,
  setAddSection,
  setItemToAddSection,
  setItemToSection
} = VoucherSlice.actions;

export default VoucherSlice;
