import { useMemo } from "react";
import AwesomeTable from "../../components/Awesome/AweomeTable";
import { Box, Button, MenuItem, TextField } from '@mui/material';
import { t } from "i18next";
import TableHeaders from "./tableHeaders";
import {
  useAddVoucherMutation,
  useDeleteVoucherMutation,
  useEditVoucherMutation,
  useGetAllVouchersQuery,
  useAddVoucherPinMutation,
} from "../../data/api/voucher";
import Loader from "../../components/Common/Loader";
import TableActions from "./tableActions";
import {
  setAddMode,
  setAdvertiseMode,
  setCustomSlideMode,
  setDeleteMode,
  setEditMode,
  setItemToAdvertise,
  setItemToCustomSlide,
  setItemToDelete,
  setItemToEdit,
  setItemToNotify,
  setItemToView,
  setNotifyMode,
  setViewMode,
  setItemToPin,
  setPinVoucher,
  setRemovePinVoucher,
  setItemToRemovePin,
  setItemToAdd,
  setAddProviderDepartment,
  setItemToProviderDepartment,
  setItemToSection,
  setItemToAddSection,
  setAddSection
} from "../../data/slices/voucherSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from 'react';
import FullScreenDialog from "../../components/Awesome/FullScreenDialog";
import AwesomeDialog from "../../components/Awesome/AwesomeDialog";
import mapToFormData from "../../utils/mapToFormData";
import ConfirmationDialog from "../../components/Dialogs/ConfirmationDialog";
import { useGetPromotionsSelectionListQuery } from "../../data/api/promotion";
import { useAdvertisVoucherMutation } from "../../data/api/advertising";
import { useCreateNotificationMutation } from "../../data/api/notification";
import { useGetProviderSelectListQuery } from "../../data/api/provider";
import {
  useAppendCustomSlideMutation,
  useGetCustomSlidesListQuery,
} from "../../data/api/customSlide";
import { useGetSectionsSelectionListQuery } from '../../data/api/section';

import {useGetNewPinnedVoucherQuery, useGetHotPinnedVoucherQuery, useGetMostSoldPinnedVoucherQuery, useRemovePinVoucherMutation, useAddToProviderDepartmentMutation, useAddToSectionMutation} from "../../data/api/voucher";

function VouchersPage() {
  const dispatch = useDispatch();
  const { data: allVouchersData, isLoading, isFetching, isError, error } =
    useGetAllVouchersQuery();

  const customSlides = useGetCustomSlidesListQuery();
  const {
    itemToView,
    viewMode,
    addMode,
    deleteMode,
    editMode,
    notifyMode,
    itemToNotify,
    itemToEdit,
    itemToDelete,
    advertiseMode,
    itemToCustomSlide,
    customSlideMode,
    itemToAdvertise,
    itemToPin,
    pinVoucher,
    itemToRemovePin,
    removePinVoucher,
    addProviderDepartment,
    itemToAdd,
    itemToProviderDepartment,
    addSection,
    itemToAddSection,
    itemToSection
  } = useSelector((state) => state.voucher);
  const [createVoucher, createVoucherResult] = useAddVoucherMutation();
  const [editVoucher, editVoucherResult] = useEditVoucherMutation();
  const [deleteVoucher, deleteVoucherResult] = useDeleteVoucherMutation();
  const [advertiseVoucher, advertiseVoucherResult] =
    useAdvertisVoucherMutation();
  const [addVouvherToCustomSlide, addVouvherToCustomSlideResult] =
    useAppendCustomSlideMutation();
  const [notifyVoucher, notifyVoucherResult] = useCreateNotificationMutation();
  const providers = useGetProviderSelectListQuery();
  const promotions = useGetPromotionsSelectionListQuery();
  const [addVoucherPin, addVoucherPinResult] = useAddVoucherPinMutation();
  const [ deletePinVoucher, deleteVoucherPinResult ] = useRemovePinVoucherMutation();
  const [ addToProviderDepartment, addToProviderDepartmentResult] = useAddToProviderDepartmentMutation();
  const [ addToSection, addToSectionResult] =   useAddToSectionMutation();
  const sections = useGetSectionsSelectionListQuery();

  const columns = useMemo(
    () =>
      TableHeaders.map((header) => ({
        ...header,
        header: t(header.header),
      })),
    []
  );

  const [filter, setFilter] = useState("all");
  const { data: newPinnedVouchersData, refetch: refetchNewPinnedVouchersData } = useGetNewPinnedVoucherQuery();
  const { data: hotPinnedVouchersData, refetch: refetchHotPinnedVouchersData } = useGetHotPinnedVoucherQuery();
  const { data: mostSoldPinnedVouchersData, refetch: refetchMostSoldPinnedVouchersData } = useGetMostSoldPinnedVoucherQuery();

  const setCreateOptions = () => {
    dispatch(setAddMode(true));
  };
  const onSubmit = (body) => {
    if (body.price_after == null || body.price_after == "") {
      body.price_after = body.price_before;
    }
    console.log(body);
    body = mapToFormData(body);

    if (addMode) createVoucher(body);
    if (editMode && itemToEdit) {
      editVoucher({ body, id: itemToEdit.id });
    }
  };
  const onAdvertise = (body) => {
    body = mapToFormData(body);
    advertiseVoucher({ body, id: itemToAdvertise.id });
  };
  const onCustomSlide = (body) => {
    addVouvherToCustomSlide({ body, id: itemToCustomSlide.id });
  };
  const onNotify = (body) => {
    body.data = JSON.stringify({ voucher_id: itemToNotify.id });
    body = mapToFormData(body);

    notifyVoucher(body);
  };

  const onPin = (body) => {
    body.voucher_id = itemToPin.id;
    body = mapToFormData(body);
    addVoucherPin(body);
  };

  const onAddProviderDepartment = (body) => {
    body.voucher_id = itemToAdd.id;
    body = mapToFormData(body);
    addToProviderDepartment(body);
  }
  if (isLoading || isFetching || isError) {
    return <Loader isError={isError} error={error?.message} />;
  }
  const onAddSection = (body) => {
    body.voucher_id = itemToAddSection.id;
    body = mapToFormData(body);
    addToSection(body);
  }
  if (isLoading || isFetching || isError) {
    return <Loader isError={isError} error={error?.message} />;
  }
  const typeList = [
    { label: t("store"), value: "store" },
    { label: t("delivery"), value: "delivery" },
    { label: t("store_and_delivery"), value: "store_and_delivery" },
  ];
  const advertisingTypeList = [
    { label: t("slider"), value: "slider" },
    { label: t("first_banner"), value: "first_banner" },
    { label: t("last_banner"), value: "last_banner" },
    { label: t("popup"), value: "popup" },
  ];
  const notificationTypeList = [
    { label: t("new"), value: "new" },
    { label: t("hot"), value: "hot" },
    { label: t("other"), value: "other" },
  ];


  return (
    <>
      <Box display="flex" flexDirection="column" flex="1" justifyContent="center" gap={2}>
        <Box display="flex" flexDirection="row" flex="1" justifyContent="center" gap={1}>
          <Box sx={{ marginLeft: '10%' }}>
            <button className="voucher-button" onClick={() => 
                  setFilter("all")}>
                     {t("AllVouchers")}
            </button>

            <button className="voucher-button" onClick={async () => {
                await refetchNewPinnedVouchersData();
                     setFilter("new");}}>
                      {t("NewPinnedVoucher")}
            </button>

            <button className="voucher-button" onClick={async () => {
                await refetchHotPinnedVouchersData();
                  setFilter("hot");}}>
                     {t("HotPinnedVoucher")} 
            </button>

            <button className="voucher-button" onClick={async () => {
                await refetchMostSoldPinnedVouchersData();
                    setFilter("most_sold");}}>
                       {t("MostSoldPinnedVoucher")} 
            </button>
          </Box>
        </Box>
      </Box>

      {filter === 'all' && allVouchersData && (
        <AwesomeTable
          columns={columns}
          data={allVouchersData.data}
          showActions
          RowActions={(row) => (
            <TableActions row={row} filter={filter} />
          )}
          setCreateOptions={setCreateOptions}
          showTopActions
        />
      )}

      {filter === 'new' && newPinnedVouchersData && (
        <AwesomeTable
          columns={columns}
          data={ newPinnedVouchersData.data }
          showActions
          RowActions={(row) => (
            <TableActions row={row} filter={filter} />
          )}
          setCreateOptions={setCreateOptions}
          showTopActions
        />
      )}

      {filter === 'hot' && hotPinnedVouchersData && (
        <AwesomeTable
          columns={columns}
          data={ hotPinnedVouchersData.data }
          showActions
          RowActions={(row) => (
            <TableActions row={row} filter={filter} />
          )}
          setCreateOptions={setCreateOptions}
          showTopActions
        />
      )}

      {filter === 'most_sold' && mostSoldPinnedVouchersData && (
        <AwesomeTable
          columns={columns}
          data={ mostSoldPinnedVouchersData.data }
          showActions
          RowActions={(row) => (
            <TableActions row={row} filter={filter} />
          )}
          setCreateOptions={setCreateOptions}
          showTopActions
        />
      )}

      <FullScreenDialog
        data={itemToView}
        columns={[
          { accessorKey: "name", header: t("Name") },
          { accessorKey: "description", header: t("Description") },
          { accessorKey: "price_before", header: t("price_before") },
          { accessorKey: "price_after", header: t("price_after") },
          { accessorKey: "location", header: t("location") },
          { accessorKey: "clicks", header: t("Clicks") },
          { accessorKey: "branch", header: t("branch") },
          { accessorKey: "expiry_date", header: t("ExpiryDate") },
          { accessorKey: "usage_count", header: t("UsageCount") },
          { accessorKey: "provider_name", header: t("provider_name") },
          { accessorKey: "type", header: t("type") },
          {accessorKey: "points", header: t("Points")},

          { accessorKey: "is_expired", type: "bool", header: t("IsExpired") },
          { accessorKey: "is_pinned", type: "bool", header: t("IsPinned") },
          { accessorKey: "images", type: "gallery", header: t("images") },
        ]}
        open={viewMode}
        onClose={() => {
          dispatch(setItemToView(null));
          dispatch(setViewMode(false));
        }}
      />

      <AwesomeDialog
        data={itemToEdit}
        columns={[
          { accessorKey: "name", required: true, header: t("Name") },
          {
            accessorKey: "description",
            required: true,
            header: t("Description"),
          },
          {
            accessorKey: "price_before",
            required: true,
            header: t("price_before"),
          },
          {
            accessorKey: "price_after",
            required: false,
            header: t("price_after"),
          },
          { accessorKey: "location", required: true, header: t("location") },
          {
            accessorKey: "expiry_date",
            required: false,
            type: "date",
            header: t("ExpiryDate"),
          },
          {
            accessorKey: "usage_count",
            required: false,
            header: t("UsageCount"),
          },
          {
            accessorKey: "points",
            required: false,
            header: t("Points")
          },
          {
            accessorKey: "type",
            type: "select",
            required: true,
            header: t("type"),
            list: typeList,
          },
          {
            accessorKey: "is_general",
            type: "bool",
            required: true,
            header: t("is_general"),
          },
          {
            accessorKey: "provider_id",
            type: "select",
            required: true,
            header: t("Provider"),
            list: providers.data?.data,
          },
          {
            accessorKey: 'section_ids',
            required: true,
            type: "multi-select",
            header: t('Section'),
            list: sections.data?.data 
          },
          {
            accessorKey: "promotion_ids",
            type: "multi-select",
            header: t("Promotion"),
            list: [
              { value: [], label: t("NoPromotion"), disabled: false },
              ...(promotions.data?.data || []),
            ],
            onChange: (selectedValues) => {
              const isNoPromotionSelected = selectedValues.includes([]);
              const updatedList = [
                { value: [], label: t("No Promotion"), disabled: isNoPromotionSelected },
                ...(promotions.data?.data || []),
              ];
              return selectedValues.filter((value) => updatedList.find((item) => item.value === value));
            },
          },
          { accessorKey: "images", type: "gallery", header: t("images") },
        ]}
        open={addMode || editMode}
        onClose={() => {
          dispatch(setEditMode(false));
          dispatch(setAddMode(false));
          dispatch(setItemToEdit(null));
        }}
        onSubmit={onSubmit}
      />

      <AwesomeDialog
        data={itemToAdvertise}
        columns={[
          { accessorKey: "lable", required: true, header: t("Label") },
          {
            accessorKey: "description",
            required: true,
            header: t("Description"),
          },
          {
            accessorKey: "expiry_date",
            required: true,
            type: "date",
            header: t("ExpiryDate"),
          },
          {
            accessorKey: "type",
            type: "select",
            required: true,
            header: t("AdvertisingType"),
            list: advertisingTypeList,
          },
          { accessorKey: "image_url", type: "image", header: t("Image") },
        ]}
        open={advertiseMode}
        onClose={() => {
          dispatch(setAdvertiseMode(false));
          dispatch(setItemToAdvertise(null));
        }}
        onSubmit={onAdvertise}
      />

      <AwesomeDialog
        columns={[
          {
            accessorKey: "custom_slide_id",
            type: "select",
            required: true,
            header: t("CustomSlider"),
            list: customSlides.data?.data,
          },
        ]}
        open={customSlideMode}
        onClose={() => {
          dispatch(setCustomSlideMode(false));
          dispatch(setItemToCustomSlide(null));
        }}
        onSubmit={onCustomSlide}
      />

      <AwesomeDialog
        columns={[
          { accessorKey: "body", required: true, header: t("Body") },
          {
            accessorKey: "type",
            required: true,
            type: "select",
            header: t("NotificationType"),
            list: notificationTypeList,
          },
          { accessorKey: "image", type: "image", header: t("Image") },
        ]}
        open={notifyMode}
        onClose={() => {
          dispatch(setNotifyMode(false));
          dispatch(setItemToNotify(null));
        }}
        onSubmit={onNotify}
      />

      <AwesomeDialog
        columns={[
          {
            accessorKey: "promotion_id",
            required: true,
            type: "select",
            header: t("PinType"),
            list: promotions.data?.data
          },
        ]}
        open={pinVoucher}
        onClose={() => {
          dispatch(setItemToPin(null));
          dispatch(setPinVoucher(false));
        }}
        onSubmit={onPin}
      />

    {filter && (
      <ConfirmationDialog
        open={removePinVoucher}
        onClose={() => {
          dispatch(setItemToRemovePin(null));
          dispatch(setRemovePinVoucher(false));
        }}
        onConfirm={() => {
          let promotion_id;
          if (filter === 'new') {
            promotion_id = 1;
          } else if (filter === 'hot') {
            promotion_id = 2;
          } else if (filter === 'most_sold') {
            promotion_id = 3;
          }

          const body = {
            voucher_id: itemToRemovePin.id,
            promotion_id: promotion_id ,
          };
          const formData = mapToFormData(body);
          deletePinVoucher(formData);
        }}
        message={t("RemovePinConfirmation")}
      />
    )}

      <ConfirmationDialog
        open={deleteMode}
        onClose={() => {
          dispatch(setItemToDelete(null));
          dispatch(setDeleteMode(false));
        }}
        onConfirm={() => {
          deleteVoucher({ id: itemToDelete.id });
        }}
        message={t("DeleteConfirmation")}
      />
       <AwesomeDialog
       data={itemToAdd && itemToProviderDepartment}
       columns={[
          {
            accessorKey: "provider_department_ids",
            required: true,
            type: "multi-select",
            header: t("AddVocherToProviderDepartment"),
            list: itemToProviderDepartment
          },
        ]}
        open={addProviderDepartment}
        onClose={() => {

          dispatch(setAddProviderDepartment(false))
          dispatch(setItemToAdd(null))
          dispatch(setItemToProviderDepartment(null))

        }}
        onSubmit={onAddProviderDepartment}
      />

      {/* <AwesomeDialog
      data={itemToAddSection}
       columns={[
          {
            accessorKey: "section_id",
            required: true,
            type: "select",
            header: t("AddVoucherToSection"),
            list: itemToSection,
          },
        ]}
        open={addSection}
        onClose={() => {

          dispatch(setAddSection(false))
          dispatch(setItemToAddSection(null))
          dispatch(setItemToSection(null))

        }}
        onSubmit={onAddSection}
      /> */}

    </>
  );
}

export default VouchersPage;
